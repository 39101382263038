import React, { useState } from 'react';
import Stack from '@mui/material/Stack';

import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import ConfirmingCreditLine from './ConfirmingCreditLine';
import { useSnackBars } from '../../../../hooks';
import { UPLOAD_CONFIRMING } from '../../../../graphql';
import useExcelTemplateDownloader from '../../../../hooks/useExcelTemplateDownloader';
import { UploadDropZoneDialog } from '../../../../components/dialogs';

const ConfirmingUploadActions = () => {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const { addAlert } = useSnackBars();
  const [uploadConfirmingExcel, { loading: loadingUpload }] = useMutation(
    UPLOAD_CONFIRMING,
    { onError: (error) => addAlert({ id: 'upload-confirming-error', severity: 'error', message: error.message }),
      onCompleted: () => addAlert({ id: 'upload-confirming-error', severity: 'success', message: 'La nómina esta siendo procesada, te llegará un correo con el resultado' }),
    },
  );
  const onCompleted = () => {
    setOpen(false);
    uploadConfirmingExcel({ variables: { confirmingFile: files[0] } });
  };
  const [downloadExcel] = useExcelTemplateDownloader('plantilla_confirming');
  return (
    <>
      <Stack
        width="80%"
        justifyContent="space-between"
        direction="row"
        spacing={2}
        py={1}
      >
        <ConfirmingCreditLine p={2} />
        <LoadingButton
          size="small"
          variant="contained"
          sx={{ alignSelf: 'center' }}
          onClick={() => setOpen(true)}
          loading={loadingUpload}
        >
          Subir nómina
        </LoadingButton>
      </Stack>
      <UploadDropZoneDialog
        open={open}
        setOpen={setOpen}
        files={files}
        loading={loadingUpload}
        setFiles={setFiles}
        message="Carga el excel con los documentos a incluir en la nómina (Formato XLSX o CSV - máximo 10Mb)"
        downloadTemplate={() => downloadExcel({
          variables: { columns: ['rut emisor', 'rut receptor', 'folio', 'tipo', 'fecha emisión', 'fecha vencimiento', 'valor'] },
        })}
        onCompleted={onCompleted}
      />
    </>
  );
};

export default ConfirmingUploadActions;
